.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  width: 100%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.h2{
  font-family: inherit; font-size:36px;color: black;font-weight: 600;padding: 12px;
}
.button{
  padding:20px;
    background-color:#002078;
    color: #fff;
    border:none;
    border-radius:10px;
    font-size:30px;
    text-decoration: none;
}
.App-header {
  background-color: #fff;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}
